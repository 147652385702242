import React from 'react';
import Schedule from './Schedule';
import Timespan from './Timespan';
import PersonalSchedule from './PersonalSchedule';
import './App.css';

function App() {

  const userSchedule = new PersonalSchedule(Schedule);
  const renderTimespans = () => Schedule.map((timespan, i) => {
    if(timespan.break) {
      return (
        <div key={i}>
          <div className="divider-container">
            <div className="divider divider-left"></div>
            <span className="time">{timespan.time}</span>
            <div className="divider divider-right"></div>
          </div>
          <div className='break'>
            { timespan.link ? <a href={timespan.link} target="_blank" rel="noopener noreferrer">{timespan.description}</a> : timespan.description }
          </div>
        </div>
      );
    } else {
      return <Timespan key={i} userSchedule={userSchedule} time={timespan.time} talks={timespan.talks}/>;
    }
  });

  return (
    <div>
      <span className="app-title">
        <img className="logo" alt="10PinesConf 2024 Logo" src="Logo2023.svg"/>
      </span>
      { renderTimespans() }
    </div>
  );
}

export default App;
