import React from 'react';
import Modal from 'react-responsive-modal';
import './Talk.css';

export default class Talk extends React.Component {
  state = {
    show: false
  };

  showModal = e => {
    this.setState({
      show: true
    });
  };

  hideModal = e => {
    this.setState({
      show: false
    });
  };

  render() {
      const meetLink = this.props.meetLink || this.props.room.meetLink;
      return (
        <div className={`talk ${this.props.room.class}`}>
          <div className="header">
            <p className="title">{this.props.title}</p>
            <p className="author">{this.props.author}</p>
            {this.props.details && <button className="button search" onClick={ this.showModal }>Conocer más</button>}

          </div>

          <div className="footer">
            <div className="container">
              {this.props.feedbackLink && <a className="link-button" target="_blank" href={this.props.feedbackLink} rel="noopener noreferrer"> Dejar Feedback ↗</a>}
              {meetLink && <a className="colored-button" target="_blank" href={meetLink} rel="noopener noreferrer"> Ir a charla ↗</a>}
            </div>
            <span class="room">Sala {this.props.room.name}</span>
          </div>
          <Modal classNames={{ modal: 'modal', closeButton: 'close-button' }} open={this.state.show} onClose={this.hideModal} center>
              <p className="title">{this.props.title}</p>
              <p>{this.props.details}</p>
          </Modal>
        </div>
    );
  }
}

